<template>
  <div class="types-box">
     <Header :title="'香港保险'" :back="true"/>
     <div class="types">
      <div class="types-icon"><img :src="require('@/assets/img/'+this.company+'.png')"/></div>
      <div class="types-title">{{this.name}}</div>
      <div class="types-subtitle">{{this.company}}</div>
      <div v-if="name!=='万年青-传承'">
        <div class="types-sex flex-box">
            <div :class="sex=='男'?'active':''" @click="changeSex('男')"><i class="el-icon-female"></i> 男</div>
            <div :class="sex=='女'?'active':''" @click="changeSex('女')"><i class="el-icon-male"></i> 女</div>
          </div>
          <div class="flex-box types-row">
              <div>供款年期</div>
              <div>10年</div>
          </div>
          <div class="flex-box types-row">
              <div>年龄</div>
              <div>
                <el-checkbox @change="handleAgeChange(0)" v-model="age[0]">10岁</el-checkbox>
                <el-checkbox @change="handleAgeChange(1)" v-model="age[1]">40岁</el-checkbox>
                <el-checkbox @change="handleAgeChange(2)" v-model="age[2]">50岁</el-checkbox>
              </div>
          </div>
          <div class="flex-box types-row">
              <div>是否吸烟</div>
              <div>
                <el-checkbox @change="handleYChange(0)" v-model="yan[0]">非吸烟</el-checkbox>
                <el-checkbox @change="handleYChange(1)" v-model="yan[1]">吸烟</el-checkbox>
              </div>
          </div>
          <div class="flex-box types-row">
              <div>基本保额</div>
              <div>
                ${{coverage|format}} 
                <el-popover
                placement="top"
                width="250"
                trigger="click"
                >
                <div>基本保额默认为10万美元。但您投保时可以选择任意保额。</div>
                <div>对同样受保人和缴费年限，保额与保费基本等比例。例如你输入受保人信息后，得到25年缴费的每年保费为1000；若需要保额调为20万美元，那么25年缴纳的保费约为每年2000。金额越大，保费有微小折扣。</div>
                <i slot="reference" class="el-icon-question theme-color"></i>
              </el-popover>
              </div>
          </div>
          <div class="flex-box types-row">
              <div>每年保费</div>
              <div class="theme-color">
                ${{premium|format}}
              </div>
          </div>
      </div>
      <div v-else>
        <div class="flex-box types-row">
          <div>供款年期</div>
          <div>5年</div>
        </div>
        <div class="flex-box types-row">
          <div>年龄</div>
          <div>
            <el-checkbox @change="handleAgeChange(1)" v-model="age[1]">40岁</el-checkbox>
            <el-checkbox @change="handleAgeChange(2)" v-model="age[2]">50岁</el-checkbox>
          </div>
        </div>
        <div class="flex-box types-row">
          <div>每年保费</div>
          <div class="theme-color">
            ${{premium|format}}
          </div>
        </div>
      </div>
      <div  class="btn-box">
        <button class="defined-btn" @click="planBooks" >生成计划参考书</button>
      </div>
    </div>
    <div class="bottom-box">上述参数仅供参考，具体数据以实际为准</div>
  </div>
</template>
<script>
import Header from '@/components/Header'
import base64 from '@/utils/base64'
export default {
  components: {
    Header
  },
  data(){
    return {
      name:'',
      compamy:'',
      radio:'',
      life:true,
      age:[false,true,false],
      yan:[true,false],
      sex:"男",
      tempAge:'40',
      tempSmoke:"非吸烟",
      coverage:100000,
      premium:34533,
      temp:[],
      resTemp:[]
    }
  },
  created(){
    this.name = this.$route.query.name
    this.company = this.$route.query.company
    this.getDetail()
  },
  methods:{
    changeType(){
      if(this.name=="万年青-传承"){
        this.resTemp =this.temp.filter(item=>item.insured_age==this.tempAge)
      }else{
        this.resTemp =this.temp.filter(item=>item.sex==this.sex&&item.insured_age==this.tempAge&&item.smoke==this.tempSmoke)
      }
      this.premium = this.resTemp[0].total
    },
    getDetail(){
      this.$store.dispatch('insurances/getInsurancesMoney',{params:{company:this.company,name:this.name}}).then(res => {
        let data = base64.decode(res.substring(2))
        data = JSON.parse(data)
        this.temp = data
        if(this.name=="万年青-传承"){
          this.resTemp =data.filter(item=>item.insured_age=='40')
        }else{
          this.resTemp =data.filter(item=>item.sex=='男'&&item.insured_age=='40'&&item.smoke=='非吸烟')
        }
        this.premium = this.resTemp[0].total
      })
    },
    planBooks(){
      localStorage.setItem('plan_data',JSON.stringify(this.resTemp[0]))
      this.$router.push({name:'plan'})
    },
    changeSex(sex){
      this.sex=sex;
      this.changeType()
    },
    handleAgeChange(num){
      this.age.map((item,index)=>{
        if(num!=index){
          this.age[index]=false
        } 
      })
      if(num==0){
        this.tempAge = 10
      }else if(num==1){
        this.tempAge = 40
      }else if(num==2){
        this.tempAge = 50
      }
      this.changeType()
    },
    handleYChange(num){
       this.yan.map((item,index)=>{
        if(num!=index){
          this.yan[index]=false
        } 
      })
      if(num==0){
        this.tempSmoke = '非吸烟'
      }else if(num==1){
         this.tempSmoke = '吸烟'
      }
      this.changeType()
    }
  }
}
</script>
<style lang="scss" scoped>
.types{
  width: 95%;
  // height: 500px;
  margin:4rem auto 2rem;
  -webkit-box-shadow:0px 0px 6px 0px rgba(141, 141, 141, 0.13);
  box-shadow: 0px 0px 6px 0px rgba(141, 141, 141, 0.13);
  position: relative;
  background: #fbfbfb;
  border-radius: 1rem;
  position: relative;
  padding-top:7rem;
  padding-bottom: 3rem;
  .types-icon{
    width: 9rem;
    height: 9rem;
    background: #ffffff;
    border-radius:50%;
    position: absolute;
    top:-3.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    left: calc(50% - 4.5rem);
    box-shadow: 0px 0px 6px 0px rgba(141, 141, 141, 0.13);
    img{
      width: 90%;
    }
  }
  .types-title{
    font-size: 1.2rem;
    color: #1D1D1D;
    letter-spacing: .2px;
    font-weight: 700;
    text-align: center;
  }
  .types-subtitle{
    width: 80%;
    margin: 0.1rem auto auto;
    font-size: .8rem;
    color: #999;
    letter-spacing: 0;
    text-align: center;
  }
  .types-sex{
    margin:2rem auto 1rem;
    width: 50%;
    justify-content: space-around;
    >div{
      width: 60px;
      line-height: 1.5;
      text-align: center;
      border-radius:4px;
      cursor: pointer;
    }
    >div.active{
      color:#fff
    }
  }
  .types-row{
    width: 80%;
    margin: 1.5rem auto .5rem;
    >:first-child{
      width: 80px;
      font-size: .9rem;
      color: #333;
    }
    >:last-child{
     color:  #606266;
     font-size: .9rem;
    }
  }
  .el-checkbox{
    margin-right: 1rem;
  }
  .el-radio__inner{
    border-radius:2px
  }
}
.btn-box{
  margin: 1rem auto;
  text-align: center;
  button{
    width: 90%;
  }
}
.bottom-box{
  text-align: center;
  font-size: .8rem;
  color: #999;
}
</style>
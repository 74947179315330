var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "types-box" },
    [
      _c("Header", { attrs: { title: "香港保险", back: true } }),
      _c("div", { staticClass: "types" }, [
        _c("div", { staticClass: "types-icon" }, [
          _c("img", {
            attrs: { src: require("@/assets/img/" + this.company + ".png") },
          }),
        ]),
        _c("div", { staticClass: "types-title" }, [_vm._v(_vm._s(this.name))]),
        _c("div", { staticClass: "types-subtitle" }, [
          _vm._v(_vm._s(this.company)),
        ]),
        _vm.name !== "万年青-传承"
          ? _c("div", [
              _c("div", { staticClass: "types-sex flex-box" }, [
                _c(
                  "div",
                  {
                    class: _vm.sex == "男" ? "active" : "",
                    on: {
                      click: function ($event) {
                        return _vm.changeSex("男")
                      },
                    },
                  },
                  [_c("i", { staticClass: "el-icon-female" }), _vm._v(" 男")]
                ),
                _c(
                  "div",
                  {
                    class: _vm.sex == "女" ? "active" : "",
                    on: {
                      click: function ($event) {
                        return _vm.changeSex("女")
                      },
                    },
                  },
                  [_c("i", { staticClass: "el-icon-male" }), _vm._v(" 女")]
                ),
              ]),
              _vm._m(0),
              _c("div", { staticClass: "flex-box types-row" }, [
                _c("div", [_vm._v("年龄")]),
                _c(
                  "div",
                  [
                    _c(
                      "el-checkbox",
                      {
                        on: {
                          change: function ($event) {
                            return _vm.handleAgeChange(0)
                          },
                        },
                        model: {
                          value: _vm.age[0],
                          callback: function ($$v) {
                            _vm.$set(_vm.age, 0, $$v)
                          },
                          expression: "age[0]",
                        },
                      },
                      [_vm._v("10岁")]
                    ),
                    _c(
                      "el-checkbox",
                      {
                        on: {
                          change: function ($event) {
                            return _vm.handleAgeChange(1)
                          },
                        },
                        model: {
                          value: _vm.age[1],
                          callback: function ($$v) {
                            _vm.$set(_vm.age, 1, $$v)
                          },
                          expression: "age[1]",
                        },
                      },
                      [_vm._v("40岁")]
                    ),
                    _c(
                      "el-checkbox",
                      {
                        on: {
                          change: function ($event) {
                            return _vm.handleAgeChange(2)
                          },
                        },
                        model: {
                          value: _vm.age[2],
                          callback: function ($$v) {
                            _vm.$set(_vm.age, 2, $$v)
                          },
                          expression: "age[2]",
                        },
                      },
                      [_vm._v("50岁")]
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "flex-box types-row" }, [
                _c("div", [_vm._v("是否吸烟")]),
                _c(
                  "div",
                  [
                    _c(
                      "el-checkbox",
                      {
                        on: {
                          change: function ($event) {
                            return _vm.handleYChange(0)
                          },
                        },
                        model: {
                          value: _vm.yan[0],
                          callback: function ($$v) {
                            _vm.$set(_vm.yan, 0, $$v)
                          },
                          expression: "yan[0]",
                        },
                      },
                      [_vm._v("非吸烟")]
                    ),
                    _c(
                      "el-checkbox",
                      {
                        on: {
                          change: function ($event) {
                            return _vm.handleYChange(1)
                          },
                        },
                        model: {
                          value: _vm.yan[1],
                          callback: function ($$v) {
                            _vm.$set(_vm.yan, 1, $$v)
                          },
                          expression: "yan[1]",
                        },
                      },
                      [_vm._v("吸烟")]
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "flex-box types-row" }, [
                _c("div", [_vm._v("基本保额")]),
                _c(
                  "div",
                  [
                    _vm._v(" $" + _vm._s(_vm._f("format")(_vm.coverage)) + " "),
                    _c(
                      "el-popover",
                      {
                        attrs: {
                          placement: "top",
                          width: "250",
                          trigger: "click",
                        },
                      },
                      [
                        _c("div", [
                          _vm._v(
                            "基本保额默认为10万美元。但您投保时可以选择任意保额。"
                          ),
                        ]),
                        _c("div", [
                          _vm._v(
                            "对同样受保人和缴费年限，保额与保费基本等比例。例如你输入受保人信息后，得到25年缴费的每年保费为1000；若需要保额调为20万美元，那么25年缴纳的保费约为每年2000。金额越大，保费有微小折扣。"
                          ),
                        ]),
                        _c("i", {
                          staticClass: "el-icon-question theme-color",
                          attrs: { slot: "reference" },
                          slot: "reference",
                        }),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "flex-box types-row" }, [
                _c("div", [_vm._v("每年保费")]),
                _c("div", { staticClass: "theme-color" }, [
                  _vm._v(" $" + _vm._s(_vm._f("format")(_vm.premium)) + " "),
                ]),
              ]),
            ])
          : _c("div", [
              _vm._m(1),
              _c("div", { staticClass: "flex-box types-row" }, [
                _c("div", [_vm._v("年龄")]),
                _c(
                  "div",
                  [
                    _c(
                      "el-checkbox",
                      {
                        on: {
                          change: function ($event) {
                            return _vm.handleAgeChange(1)
                          },
                        },
                        model: {
                          value: _vm.age[1],
                          callback: function ($$v) {
                            _vm.$set(_vm.age, 1, $$v)
                          },
                          expression: "age[1]",
                        },
                      },
                      [_vm._v("40岁")]
                    ),
                    _c(
                      "el-checkbox",
                      {
                        on: {
                          change: function ($event) {
                            return _vm.handleAgeChange(2)
                          },
                        },
                        model: {
                          value: _vm.age[2],
                          callback: function ($$v) {
                            _vm.$set(_vm.age, 2, $$v)
                          },
                          expression: "age[2]",
                        },
                      },
                      [_vm._v("50岁")]
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "flex-box types-row" }, [
                _c("div", [_vm._v("每年保费")]),
                _c("div", { staticClass: "theme-color" }, [
                  _vm._v(" $" + _vm._s(_vm._f("format")(_vm.premium)) + " "),
                ]),
              ]),
            ]),
        _c("div", { staticClass: "btn-box" }, [
          _c(
            "button",
            { staticClass: "defined-btn", on: { click: _vm.planBooks } },
            [_vm._v("生成计划参考书")]
          ),
        ]),
      ]),
      _c("div", { staticClass: "bottom-box" }, [
        _vm._v("上述参数仅供参考，具体数据以实际为准"),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "flex-box types-row" }, [
      _c("div", [_vm._v("供款年期")]),
      _c("div", [_vm._v("10年")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "flex-box types-row" }, [
      _c("div", [_vm._v("供款年期")]),
      _c("div", [_vm._v("5年")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }